<template>
  <div>
    Admin Dashboard
  </div>
</template>

<script>

export default {
  activated () {
    this.$store.commit('setModule', {
      name: this.$t('t.Dashboard'),
      icon: 'i.Dashboard'
    })
  }
}
</script>
